import request from '@/utils/request/request';

export async function sendCodeSms(params: any) {
  return request.post<any, any>('/index/login/sendCodeSms', params);
}
export async function onLoginByPhone(params: any) {
  return request.post<any, any>('/index/login/onLoginByPhone', params);
}
export async function getRecordList(params: any) {
  return request.post<any, any>('/index/record/getRecordList', params);
}
export async function getRecordData(params: any) {
  return request.post<any, any>('/index/record/getRecordData', params);
}
export async function updateRecordFinishData(params: any) {
  return request.post<any, any>('/index/record/updateRecordFinishData', params);
}
export async function faceDetect(params: any) {
  return request.post<any, any>('/tool/aip/faceDetect', params);
}
export async function faceMatch(params: any) {
  return request.post<any, any>('/tool/aip/faceMatch', params);
}
export async function uploadFile(params: any) {
  return request.post<any, any>('/index/file/uploadFile', params);
}
export async function uploadBase64File(params: any) {
  return request.post<any, any>('/index/file/uploadBase64File', params);
}
