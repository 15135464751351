/* unplugin-vue-components disabled */import { Empty as __unplugin_components_2 } from 'vant/es';import 'vant/es/empty/style/index';
import { Divider as __unplugin_components_1 } from 'vant/es';import 'vant/es/divider/style/index';
import { Tag as __unplugin_components_0 } from 'vant/es';import 'vant/es/tag/style/index';
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  "class": "p4",
  style: {
    "background-color": "#f5f5f5",
    "height": "100vh"
  }
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  "class": "flex flex-items-center"
};
var _hoisted_4 = {
  "class": "font-size-4 mr-2 ml-2 font500"
};
var _hoisted_5 = {
  "class": "font-size-4 font500"
};
var _hoisted_6 = {
  "class": "font-size-4"
};
var _hoisted_7 = {
  "class": "text-right font-size-4 color-gray mt-3"
};
import { getRecordList } from '@/api/main';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var user_phone = '';
    var record = ref([]);
    var statusMap = {
      0: {
        text: '未认证',
        color: '#1989fa'
      },
      1: {
        text: '已认证',
        color: 'gray'
      }
    };
    var searchParams = new URLSearchParams(window.location.search);
    user_phone = searchParams.get('user_phone');
    getRecordList({
      user_phone: user_phone
    }).then(function (res) {
      if (res.code == 200) {
        record.value = res.data.record;
      }
    });
    var getRecordDetail = function getRecordDetail(record) {
      if (record.record_status == 1) {
        window.location.href = '/index/index/record.html?record_id=' + record.record_id;
      } else {
        window.location.href = '/auth/' + record.record_id;
      }
    };
    return function (_ctx, _cache) {
      var _component_van_tag = __unplugin_components_0;
      var _component_van_divider = __unplugin_components_1;
      var _component_van_empty = __unplugin_components_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(record), function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          "class": "bg-white border-rd p3 mb-3",
          onClick: function onClick($event) {
            return getRecordDetail(item);
          }
        }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_tag, {
          color: statusMap[item.record_status].color,
          style: {
            "padding": "2px 5px"
          },
          "class": "font-size-3"
        }, {
          "default": _withCtx(function () {
            return [_createTextVNode(_toDisplayString(statusMap[item.record_status].text), 1)];
          }),
          _: 2
        }, 1032, ["color"]), _createElementVNode("span", _hoisted_4, _toDisplayString(item.person_name), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(item.person_phone), 1)]), _createVNode(_component_van_divider), _createElementVNode("div", _hoisted_6, "截止时间：" + _toDisplayString(item.record_end_time), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.record_status == 1 ? item.record_finish_time + '已认证' : '请尽快完成认证'), 1)], 8, _hoisted_2);
      }), 128)), _unref(record).length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        description: "暂无认证记录"
      })) : _createCommentVNode("", true)]);
    };
  }
};