import { createApp } from 'vue';
import App from './index.vue';
import 'uno.css';
// import '@varlet/touch-emulator';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
// import { registerPlugins } from '@/utils/vuetifyPLugins/index';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
// registerPlugins(app);
app.use(pinia).mount('#app');
