import axios from 'axios';
import type { InternalAxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 6000,
});
export type RequestError = AxiosError<{
    message?: string;
    result?: any;
    errorMessage?: string;
    msg?: string;
}>;



// 异常拦截处理器
const errorHandler = (error: AxiosError<any>): Promise<any> => {
    if (error.response) {
        const { data = {}, status, statusText } = error.response;
        // 403 无权限
        if (status === 403) {
            console.log((data && data?.message) || statusText);

        }


        if (status == 401) {
            console.log(401);

        }
    }
    return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (
    config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
    // const savedToken = localStorage.get(STORAGE_TOKEN_KEY);
    // 如果 token 存在
    // 让每个请求携带自定义 token, 请根据实际情况修改
    // if (savedToken) {
    //   config.headers[REQUEST_TOKEN_KEY] = savedToken;
    // }
    return config;
};

// 响应拦截器
const responseHandler = (response: AxiosResponse<any>) => {
    if (response.data.code == 401) {
        // 如果你需要直接跳转登录页面
        //   localStorage.set(STORAGE_TOKEN_KEY, null);

    }
    return response.data;
};

request.interceptors.request.use(requestHandler, errorHandler);
request.interceptors.response.use(responseHandler, errorHandler);
export default request;

